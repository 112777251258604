import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { getNodesFromQuery } from "../functions"

const ContactPage = ({ data }) => {
  const programs = getNodesFromQuery(data.programs.edges)

  return (
    <Layout>
      <Seo title="program" />
      <section className="article__content">
        <h1 className="article__heading">Program</h1>
      </section>

      <section className="article__content">
        <h2 className="article__heading">Programblad</h2>
        <ul className="list list--indented list--disc">
          {programs.map((program, index) => (
            <li className="program" key={index}>
              <a
                href={`/program/${program.startDate}_${program.endDate}.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                {program.startDate} - {program.endDate}
              </a>
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    programs: allProgramsYaml {
      edges {
        node {
          startDate
          endDate
        }
      }
    }
  }
`

export default ContactPage
